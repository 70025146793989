import { useState } from 'react'
import './App.css'
import {BrowserRouter, Link, Route, Routes} from "react-router-dom";
import { BarChart, Bar, Legend, ResponsiveContainer} from 'recharts';

function App () {
    return(
        <BrowserRouter>
                <Routes>
                    <Route path="/achtvs" element={<Achtvs/>}/>
                    <Route exact path='/' element={<App2/>}></Route>
                    <Route exact path='/c2' element={<C2/>}></Route>
                    <Route exact path='/c3' element={<C3/>}></Route>
                    <Route exact path='/c4' element={<C4/>}></Route>
                    <Route exact path='/c5' element={<C5/>}></Route>
                    <Route exact path='/c6' element={<C6/>}></Route>
                    <Route exact path='/c7' element={<C7/>}></Route>
                </Routes>
        </BrowserRouter>
    );
}

function C2 () {
    return (
        <body>
        <div className="header">
            <a className="title">Wirtschaftskrise oder Finanzkrise</a>
            <div className="header-right">
            <Link to="/">
                        <img src={"home.png"} id="nav-home-img" alt="Home" />
            </Link>
            </div>
        </div>

        <div className="vertical">
                <nav className="sidenav">
                    <Link className={"hinter"} to="/c4">Finanz- oder Wirtschaftskrise?</Link>
                    <Link className={"hinter"} to="/achtvs">2008 verglichen mit 2022</Link>
                    <Link className={"hinter"} to="/c2">Schweiz und USA im Vergleich</Link>
                    
                    
                    <Link className={"hinter"} to="/c5">Inflation</Link>
                    <Link className={"hinter"} to="/c3">Auswirkungen einer Inflation</Link>
                    <Link className={"hinter"} to="/c6">Inflationsrechner</Link>
                    <Link className={"hinter"} to="/c7">Quellenverzeichnis</Link>
                </nav>
        </div>
        <div className={"man"}>
                        <p id={"text"}> 
                                    <h3>Inflationsrate</h3>

                        Die Inflationsrate wird anhand des VPI (Verbraucherpreisindex) berechnet. Dieser misst die durchschnittliche Preisentwicklung der Waren und Dienstleistungen wie zum Beispiel Bekleidung, Nahrungsmittel oder Mieten. Der VPI ist der zentrale Indikator zur Beurteilung der Geldwertentwicklung in einem Land. Er wird berechnet, indem man die Preise eines Produktes zu zwei Zeitpunkten vergleicht. Oft ist dies im Vergleich zum Vormonat oder dem Vorjahresmonat. Der VPI der USA lag im August 2022 bei 8,263% im Vergleich zum Vorjahresmonat. Die Entwicklung zeigt jedoch wieder Raum für Optimismus seit dem Höhepunkt von 9,060% im Juni 2022 ist der VPI nämlich nur gesunken. In der Schweiz ist die Lage weniger dramatisch. Der VPI liegt im August 2022 im Vergleich zum August 2021 bei 3.5%. Jedoch steigt er in der Schweiz weiter an während er sich in den Vereinigten Staaten bereits leicht zurückentwickelt.  

                        
                            <img id='swissUSA' src='/swi_usa.jpg'></img>
                        

                        <h3>Bruttoinlandprodukt</h3> 

                        Das Bruttoinlandsprodukt (kurz BIP) beschreibt die wirtschaftliche Leistung eines Landes innerhalb eines bestimmten Zeitraums (meistens 1 Jahr). Das BIP misst, die den Wert der Waren und Dienstleistungen, welche in einem Land hergestellt werden. Die Veränderungsrate des BIP kann als Messgrösse für das Wirtschaftswachstum einer Volkswirtschaft gesehen werden. Aktuell verliert die USA bisher gegenüber dem 1.Quartal ca. 0.14% des BIP’s. Auch im Vergleich mit dem letzten Quartal des Vorjahres hat man im 1. Quartal 2022 bereits an BIP verloren. Gegenüber dem Vorjahr sind es jedoch positive 1.71%. Für dieses Jahr wird aber ein Wachstum von ca. 2,3% prognostiziert. Der BIP der Schweiz ist im Vergleich seit der Aufhebung der Corona-Massnahmen angestiegen. Nach einem starken Anstieg Mitte 2021 ist die Steigung etwas abgeflacht und wuchs zuletzt um 0,3%. Die BIP Entwicklung der Schweiz und USA ist somit ähnlich in den letzten 2 Jahren mit der Ausnahme, dass das BIP in den USA seit Dezember 2021 leicht sinkt. 

                        

                        <h3>Arbeitslosenrate</h3> 
                        <img src='homeless.jpg' id='ohneJob'></img>
                        Die Arbeitslosenquote verringert sich in beiden Ländern stark. In der Schweiz waren im August 2022 ungefähr 2% der Bevölkerung Arbeitslos. Dies sind 102 Personen weniger als in dem Vormonat und fast 35'000 weniger im Vergleich zum Vorjahresmonat. Dies entspricht einer Abnahme von 27,7% innerhalb eines Jahres.  

                        In den USA liegt die Arbeitslosenquote im August 2022 bei 3,5%. Im Vorjahresmonat lag diese noch bei 5,4%. Dies ist eine sehr starke Abnahme und wird gemäss Prognosen weiter sinken. 

                     

                        <h3>Fazit</h3> 

                        Die oben genannten Punkte zeigen klar auf, dass die Schweiz wirtschaftlich deutlich besser dran ist als die USA. Die Inflationsrate mach den USA merklich zu schaffen. Die Arbeitslosenquote entwickelt sich jedoch in beiden Ländern positiv. 

                        Auch das Bruttoinlandprodukt ist im Vergleich mit dem Vorjahr bei beiden durchaus positiv, auch wenn momentan die USA sich eher negativ entwickelt. 
                                    </p>
        </div>
        </body> 
  )
}

function C3 () {
    return (
        <body>
        <div className="header">
            <a className="title">Wirtschaftskrise oder Finanzkrise</a>
            <div className="header-right">
            <Link to="/">
                        <img src={"home.png"} id="nav-home-img" alt="Home" />
            </Link>
            </div>
        </div>

        <div className="vertical">
                <nav className="sidenav">
                    <Link className={"hinter"} to="/c4">Finanz- oder Wirtschaftskrise?</Link>
                    <Link className={"hinter"} to="/achtvs">2008 verglichen mit 2022</Link>
                    
                    <Link className={"hinter"} to="/c2">Schweiz und USA im Vergleich</Link>
                    <Link className={"hinter"} to="/c5">Inflation</Link>
                    <Link className={"hinter"} to="/c3">Auswirkungen einer Inflation</Link>
                    <Link className={"hinter"} to="/c6">Inflationsrechner</Link>
                    <Link className={"hinter"} to="/c7">Quellenverzeichnis</Link>
                </nav>
        </div>
        <div className={"man"}>
                    <p id={"text"}>
                        <h3>Auswirkungen Inflation auf den Alltag </h3>

                        Legt man sich jeden Monat ein Bisschen Geld zur Seite, spart man im Normalfall über eine gewisse Zeit einen beträchtlichen Betrag zusammen und kann sich etwas Schönes zulegen. Eine Inflation macht einem hier jedoch einen Strich durch die Rechnung. Das Ersparte Geld hat plötzlich nicht mehr den Wert, den es einmal hatte und man bekommt für sein erspartes nicht mehr die gewünschten Güter oder Produkte. 

                        Inflation steht für einen anhaltenden Prozess der Geldentwertung. Das heisst, die Kaufkraft des Geldes sinkt und man erhält für zum Beispiel 10 Franken nicht mehr das, was man sonst für 10 Franken erhalten hätte. Der einzige Weg, sein Vermögen während so einer Zeit nicht zu verlieren, sind Anlagen, die Ihren Wert behalten und während einer Inflation lediglich im Preis ansteigen. 
                        <img src='aus.jpg' id='gazer'></img>
                        Grundsätzlich ist Inflation nicht schlimm, die EZB (Europäische Zentralbank) versucht sogar eine Inflationsrate von 2% beizubehalten, da die ständige Preisentwicklung der Wirtschaft hilft. Steigt die Inflationsrate jedoch über 2% und bleibt darüber oder steigt sogar laufend an, macht sich dies für Konsumenten im Alltag bemerkbar. Spürbare Auswirkungen sind zum Beispiel der Anstieg von Preisen für Lebensmittel. Da die Preise für flexibler sind als die Höhe der ausbezahlten Gehälter, besteht die Gefahr, dass man als durchschnittlicher Endkonsument in einen Engpass gerät und je nach Situation nicht mehr für seinen eigenen Lebensunterhalt aufkommen kann.  

                        Entwickelt sich die Inflation schlimm weiter, so kann es sogar zu einer sogenannten Hyperinflation kommen, wobei Preise jeden Monat um über 50% ansteigen. Folgen einer solchen Hyperinflation können tragisch sein und enden oft in einer Währungsreform. Gründe für eine Hyperinflation sind oft Kriege oder Staatsversagen wie zum Beispiel in Österreich während 1919-1924, wo die Krone durch den Schilling ersetzt werden musste. 

                        <img src='aus2.jpg' id='gazero'></img>
                        So tragisch diese Auswirkungen auch sein mögen, kann eine Inflation jedoch auch Vorteile haben. So ist das Abbezahlen von Schulden während einer Inflation einfacher, da der Wert des Geldes sinkt und man somit über geraume Zeit einen Höheren Betrag an Geld besitzt. Gleichzeitig steigt jedoch der Betrag einer abzubezahlenden Schuld wie einem Kredit oder ähnlichem nicht an, wodurch man eine Geldschuld einfacher abbezahlen kann. Dies gilt auch für den Staat, der seine Schulden tendenziell einfacher abbezahlen kann in einer Phase der Inflation.  

                        Zusammenfassend ist eine Inflation an sich nichts schlimmes, falls es sich um einen geringen Preisanstieg handelt. Solange sich die Inflationsrate nicht ständig erhöht oder sogar eine Hyperinflation ausbricht, ist man also sicher. 
                    </p>
        </div>
        </body> 
  )
}

function C4 () {
    return (
        <body>
        <div className="header">
            <a className="title">Wirtschaftskrise oder Finanzkrise</a>
            <div className="header-right">
            <Link to="/">
                        <img src={"home.png"} id="nav-home-img" alt="Home" />
            </Link>
            </div>
        </div>

        <div className="vertical">
                <nav className="sidenav">   
                    <Link className={"hinter"} to="/c4">Finanz- oder Wirtschaftskrise?</Link>
                    <Link className={"hinter"} to="/achtvs">2008 verglichen mit 2022</Link>
                    <Link className={"hinter"} to="/c2">Schweiz und USA im Vergleich</Link>
                    <Link className={"hinter"} to="/c5">Inflation</Link>
                    <Link className={"hinter"} to="/c3">Auswirkungen einer Inflation</Link>
                    <Link className={"hinter"} to="/c6">Inflationsrechner</Link>
                    <Link className={"hinter"} to="/c7">Quellenverzeichnis</Link>
                </nav>
        </div>
        <div className={"man"}>
            <p id={"text"}>
                            <h3>Finanz- vs. Wirtschaftskrise </h3>

                            

                            <h3>Was ist eine Finanzkrise?</h3> 
                            <img src='gazo.jpg' id='gazer'></img>
                            Eine Finanzkrise ist eine Form der Wirtschaftskrise, die man durch eine abrupte und extreme Verschlechterung im Finanzsystem erkennen kann. Deutlich wird sie vorallem durch fallende Vermögenswerte und durch die finanzielle Not die durch die Krise in gewissen Ländern entsteht. Eine Finanzkrise kann eines oder mehrere Länder betreffen. Im schlimmsten Fall sogar die ganze Welt. 

                            Das wohl beste Beispiel für eine Finanzkrise ist die Immobilienkrise 2008. 

                    
                            
                            

                            <h3>Was ist eine Wirtschaftskrise?</h3> 

                            Eine Wirtschaftskrise ist ein laufender Prozess der Abnahme vom Wirtschaftszyklus eines Landes oder mehrerer Länder. Die Wirtschaftskrise muss nicht alle betreffen. Sie kann auch nur bestimmte Wirtschaftsbereiche oder nur bestimmte Länder betreffen. Wenn sie aber die ganze Welt betrifft sprechen wir von einer Weltwirtschaftskrise. Falls die Wirtschaftskrise über mehr als zwei Quartale hinreicht, handelt es sich hierbei um eine Rezession. Ein gutes Beispiel für eine Weltwirtschaftskrise ist bspw. Die Krise nach dem Zusammenbruch der New Yorker Börse 1929. 

                     

                            

                            <h3>Fazit</h3> 

                            Es ist nicht leicht diese beiden Themen zu vergleichen, da die Finanzkrise eine Art einer Wirtschaftskrise darstellt. Eine Wirtschaftskrise muss keine Finanzkrise sein, eine Finanzkrise muss jedoch eine Wirtschaftskrise sein. Mein Fazit ist also, dass eine Wirtschaftskrise gravierender sein kann aber die Begriffe auf eine Art Hand in Hand gehen. 

 

 
            </p>
        
        </div>
        </body> 
  )
}

function C5 () {
    return (
        <body>
        <div className="header">
            <a className="title">Wirtschaftskrise oder Finanzkrise</a>
            <div className="header-right">
            <Link to="/">
                        <img src={"home.png"} id="nav-home-img" alt="Home" />
            </Link>
            </div>
        </div>

        <div className="vertical">
                <nav className="sidenav"> 
                    <Link className={"hinter"} to="/c4">Finanz- oder Wirtschaftskrise?</Link>
                    <Link className={"hinter"} to="/achtvs">2008 verglichen mit 2022</Link>
                    <Link className={"hinter"} to="/c2">Schweiz und USA im Vergleich</Link>
                    <Link className={"hinter"} to="/c5">Inflation</Link>
                    <Link className={"hinter"} to="/c3">Auswirkungen einer Inflation</Link>
                    <Link className={"hinter"} to="/c6">Inflationsrechner</Link>
                    <Link className={"hinter"} to="/c7">Quellenverzeichnis</Link>
                </nav>
        </div>
        <div className={"man"}>
            
            <p id={"text"}><h3>Inflation </h3>

                        Inflation sollte uns allen mittlerweile ein begriff sein. Jedoch ist nicht allen klar, für was dieses Wort genau steht und was so eine Inflation wirklich mit sich bringt. Grundsätzlich steht Inflation für den Anstieg der Preise für jegliche Güter. Das bedeutet, dass sogleich der Wert des Geldes sinkt. Für eine bestimmte Geldeinheit bekommt man nicht mehr das, was man sonst dafür erhalten hätte. Das Gegenteil davon ist die Deflation, wo das Preisniveau einer Ökonomie sinkt und das Geld somit an Kaufkraft zunimmt. 

                        Die Inflation kann man in verschiedene Untergruppen aufteilen. Diese variieren je nach Inflationsrate und geben an, wie schnell ein Preisanstieg zustande kommt. Die erste dieser Abspaltungen ist die schleichende Inflation, bei der die Inflationsrate bei höchstens 5% pro Jahr liegt. Weiter gibt es die trabende Inflation, mit einer Inflationsrate zwischen 5% und 20%. Noch verheerender ist die galoppierende Inflation, welche eine Inflationsrate von 20-100% vorweist und zu guter Letzt die Hyperinflation, die eine Inflationsrate von über 100% bedeutet. Je höher die Inflationsrate liegt, umso schneller entstehen spürbare Veränderungen für Endkonsumenten. Nicht nur das, sondern die ganze Wirtschaft kann darunter leiden. 

                        Ein Beispiel für die tragischste Art der Inflation, der Hyperinflation, bietet Österreich nach 1914. Um den Krieg zu finanzieren, wurden von der damaligen Währung, der Krone, immer mehr Noten gedruckt. Die Menge an Banknoten im Umlauf stieg von Ende 1913 bis November 1918 auf das fast 15-fache. Dies resultierte in einem plötzlichen Fall der Kaufkraft der Krone. In Verbindung mit der durch den Krieg ausgelösten steigenden Nachfrage nach verschiedensten Gütern, kam es zu einer Hyperinflation. Das Resultat dieser Hyperinflation war die Einführung des Schillings als Nationalwährung, da die Krone über Zeit praktisch wertlos wurde. 

                        Ein weiteres Beispiel für eine Hyperinflation/Inflation wäre Deutschland in den Jahren 1914-23. Damals geriet es so aus der Hand, dass die Menschen so schnell wie möglich nach der Lohnauszahlung zu den Geschäften stürzten um das Geld, welches rapide an Wert verlor sinnvoll loszuwerden. 

                        Die Händler hörten auf Geld als Währung anzunehmen und bestanden auf essenzielle Tauschware. 

                        <img src='inflasione.jpg' id='inflasione'/>

                        Angebahnt hat sich diese Inflation über einen längeren Zeitraum. 1914 mit dem Start des 1. Weltkriegs begann der deutsche Staat mit der Inflationspolitik. Die deutschen Kriegsschulden wurden nicht durch höhere Steuern bezahlt. Somit verschuldete sich der Staat bei dem Volk und der Reichsbank, welche durch die Verschuldung des Staates begann, Banknoten zu drucken. 

                        1918 wurde es schlimmer, da zu Kriegsende die deutschen Kriegsschulden beglichen werden mussten. Dies taten sie durch Kredite der Reichsbank, was zum Druck neuer Banknoten führte. Das Angebot entwickelte sich nicht mit, dadurch stiegen die Preise heftig. 

                         1923 schlussendlich traf das ein, was man schon 1914 erwarten hätte können, und das Geld verlor seinen vollständigen Wert und seinen Zeck als Zahlungsmittel. Die Kriegsschulden des deutschen Staates beliefen sich auf 154 Milliarden Mark was nach der 1923 durchgeführten Reform ca. 15 Pfennigen entsprach. 

                        Das schlimmste Beispiel einer Inflation findet sich allerdings in Ungarn. Zwischen 1945 und 1946, direkt nach dem Ende des 2. Weltkrieges ereignete sich die größte jemals erreichte Inflation der Geschichte. Die Preise verdoppelten sich im Durchschnitt alle 15 Stunden. Die tägliche(!) Preissteigerungsrate lag bei 207 Prozent. Die Krise begann, nachdem Ungarn durch die Rote Armee besetzt wurde und eine Übergangsregierung an der Macht war. Ungarn hatte große Mengen an Schulden und der Wiederaufbau der Wirtschaft war ein schwieriger und langer Prozess. Das Land war großenteils zerstört und neue Güter zu produzieren erwies sich als äußerst schwer. Die perfekte Ausgangslage für eine Inflation. Es ging so weit, dass ein Tausender Schein nicht mehr der wertvollste war, sondern ein Eine-Million-Schein eingeführt werden musste. Dieser Wahnsinn geht so weit, bis ein 100-Millionen-Billionen-Schein, also ein 100-Trillionen-Schein eingeführt werden müssen. Das führte dazu das die Bevölkerung wieder mit dem Tauschhandel begonnen hat. Diese Hyperinflation konnte nach einem Jahr beendet werden durch die Einführung einer neuen Währung. Die normale Wirtschaftspolitik wurde wieder möglich.  😊  

 
            </p>
        </div>
        </body> 
  )
}

function App2 () {
    return (
        <body>
        <div className="header">
            <a className="title">Wirtschaftskrise oder Finanzkrise</a>
            <div className="header-right">
            <Link to="/">
                        <img src={"home.png"} id="nav-home-img" alt="Home" />
            </Link>
            </div>
        </div>

                <div className="vertical">
                <nav className="sidenav">
                    <Link className={"hinter"} to="/c4">Finanz- oder Wirtschaftskrise?</Link>
                    <Link className={"hinter"} to="/achtvs">2008 verglichen mit 2022</Link>
                    <Link className={"hinter"} to="/c2">Schweiz und USA im Vergleich</Link>
                    <Link className={"hinter"} to="/c5">Inflation</Link>
                    <Link className={"hinter"} to="/c3">Auswirkungen einer Inflation</Link>
                    <Link className={"hinter"} to="/c6">Inflationsrechner</Link> 
                    <Link className={"hinter"} to="/c7">Quellenverzeichnis</Link>
                </nav>
 </div>
                <div className={"man"}>
            <p id={"text"}>
                <h3>Über unsere Seite</h3>
                Auf dieser Seite werden die Begriffe Inflation, Finanz- und Wirtschaftskrise behandelt. Vor allem der Unterschied von einer Wirtschafts- zu einer Finanzkrise soll geklärt werden. Ebenso hat die Seite mit dem Inflationsrechner einen interaktiven Teil. Der Inflationsrechner soll einem den Begriff Inflation ein Bisschen näher bringen, indem man selber die Veränderung von Preisen und Kaufkraft berechnen kann. Ausserdem kann man die Inflationsrate berechnen.
                <h3>Aufbau</h3>
                Auf der ersten Seite werden die Finanz- und die Wirtschaftskrise im Allgemeinen verglichen. Weiter wird dieser Unterschied anhand der Krisen im Jahr 2008 und 2022 aufgezeigt. Auf der nächsten Seite wird dann die Lage in der Schweiz bzw. in den USA verglichen. Zum Schluss wird der Begriff Inflation noch erklärt. Zuerst mit einem Inflationsrechner, dann mit 2 Seiten über die Inflation selbst bzw. den Auswirkungen einer Inflation auf den Alltag.
                <img src={"titel.jpg"} id='gazero'></img>
                <h3>Erklärung Inflationsrechner</h3>
                    Der Inflationsrechner bietet die Möglichkeit einerseits den Endpreis eines Produkts bzw. den Betrag, den man für den gleichen Wert benötigt und den Verlust an Kaufkraft anhand der Inflationsrate und dem Startpreis über einen bestimmten Zeitraum zu berechnen. Ebenfalls kann man den Betrag berechnen, den man für das Zurückzahlen einer Geldschuld benötigt. Dazu muss man lediglich die Kaufkraft anschauen, die ein Betrag nach der gewünschten Dauer noch beträgt.

            </p>
            
       
        </div>
        </body> 
  )
}

function C7 () {
    return (
        <body>
        <div className="header">
            <a className="title">Wirtschaftskrise oder Finanzkrise</a>
            <div className="header-right">
            <Link to="/">
                        <img src={"home.png"} id="nav-home-img" alt="Home" />
            </Link>
            </div>
        </div>

                <div className="vertical">
                <nav className="sidenav">
                    <Link className={"hinter"} to="/c4">Finanz- oder Wirtschaftskrise?</Link>
                    <Link className={"hinter"} to="/achtvs">2008 verglichen mit 2022</Link>
                    <Link className={"hinter"} to="/c2">Schweiz und USA im Vergleich</Link>
                    <Link className={"hinter"} to="/c5">Inflation</Link>
                    <Link className={"hinter"} to="/c3">Auswirkungen einer Inflation</Link>
                    <Link className={"hinter"} to="/c6">Inflationsrechner</Link>
                    <Link className={"hinter"} to="/c7">Quellenverzeichnis</Link>
                </nav>
 </div>
                <div className={"man"}>
            <p id={"text"}>
                <h3>Quellenverzeichnis</h3>
                <h4>Textquellen (letzter Abruf 12.12.2022)</h4>
                <h5>2008 vs 2022</h5>
                <a target='_blank' href='https://www.postfinance.ch/de/privat/beduerfnisse/anlagewissen/finanzkrise.html '>https://www.postfinance.ch/de/privat/beduerfnisse/anlagewissen/finanzkrise.html </a>
                <a target='_blank' href='https://www.costconsult.de/wirtschaftskrise-2022/ '>https://www.costconsult.de/wirtschaftskrise-2022/ </a>
                <h5>Schweiz und USA im Vergleich</h5>
                <a target='_blank' href='https://de.wikipedia.org/wiki/Inflation'>https://de.wikipedia.org/wiki/Inflation</a>
                <a target='_blank' href='https://www.destatis.de/DE/Themen/Wirtschaft/Volkswirtschaftliche-Gesamtrechnungen-Inlandsprodukt/Methoden/bip.html#:~:text=Was%20beschreibt%20das%20Bruttoinlandsprodukt%20(BIP)%3F&text=Es%20misst%20den%20Wert%20der,Waren%20und%20Dienstleistungen%20verwendet%20werden.'>https://www.destatis.de/DE/Themen/Wirtschaft/Volkswirtschaftliche-Gesamtrechnungen-Inlandsprodukt/Methoden/bip.html#:~:text=Was%20beschreibt%20das%20Bruttoinlandsprodukt%20(BIP)%3F&text=Es%20misst%20den%20Wert%20der,Waren%20und%20Dienstleistungen%20verwendet%20werden.</a>
                <a target='_blank' href='https://de.statista.com/statistik/daten/studie/288903/umfrage/arbeitslosenquote-in-der-schweiz-nach-monaten/#:~:text=Arbeitslosenquote%20in%20der%20Schweiz%20nach%20Monaten%20bis%20Oktober%202022&text=Im%20Oktober%202022%20lag%20die,Prozentpunkt%20geringer%20als%20im%20Vorjahresmonat.'>https://de.statista.com/statistik/daten/studie/288903/umfrage/arbeitslosenquote-in-der-schweiz-nach-monaten/#:~:text=Arbeitslosenquote%20in%20der%20Schweiz%20nach%20Monaten%20bis%20Oktober%202022&text=Im%20Oktober%202022%20lag%20die,Prozentpunkt%20geringer%20als%20im%20Vorjahresmonat.</a>
                <a target='_blank' href='https://de.statista.com/statistik/daten/studie/193941/umfrage/monatliche-arbeitslosenquote-in-den-usa-saisonbereinigt/#:~:text=Die%20Arbeitslosenquote%20in%20den%20USA,Dezember%202014%20bis%20November%202022.'>https://de.statista.com/statistik/daten/studie/193941/umfrage/monatliche-arbeitslosenquote-in-den-usa-saisonbereinigt/#:~:text=Die%20Arbeitslosenquote%20in%20den%20USA,Dezember%202014%20bis%20November%202022.</a>
                <h5>Auswirkungen einer Inflation</h5>
                <a target='_blank' href='https://www.sparkasse.de/themen/wertpapiere-als-geldanlage/inflation.html#:~:text=Ein%20Auge%20auf%20die%20Inflation,f%C3%BCr%20ein%20stabiles%20wirtschaftliches%20Wachstum.'>https://www.sparkasse.de/themen/wertpapiere-als-geldanlage/inflation.html#:~:text=Ein%20Auge%20auf%20die%20Inflation,f%C3%BCr%20ein%20stabiles%20wirtschaftliches%20Wachstum.</a>
                <h5>Finanz- vs. Wirtschaftskrise</h5>
                <a target='_blank' href='https://de.wikipedia.org/wiki/Finanzkrise'>https://de.wikipedia.org/wiki/Finanzkrise</a>
                <a target='_blank' href='https://www.politik-lexikon.at/wirtschaftskrise/'>Börse 1929. https://www.politik-lexikon.at/wirtschaftskrise/</a>
                <h5>Inflation</h5>
                <a target='_blank' href='https://www.bankingclub.de/news/kopf-oder-zahl/ungarn-ist-rekordhalter-fuer-die-groesste-jemals-erreichte-inflation-der-geschichte/ '>https://www.bankingclub.de/news/kopf-oder-zahl/ungarn-ist-rekordhalter-fuer-die-groesste-jemals-erreichte-inflation-der-geschichte/ </a>
                <a target='_blank' href='https://www.weltsparen.de/glossar/inflation/'>https://www.weltsparen.de/glossar/inflation/</a>
                <h4>Bildquellen 12.12.2022</h4>
                <h5>2008 vs 2022</h5>
                <a target='_blank' href='https://image.jimcdn.com/app/cms/image/transf/none/path/s3ecb96fc918b9a61/image/ib217f557c070fa0a/version/1545999570/karikatur-finanzmarktkrise-finanzkrise-2008-bankenkrise.jpg '>https://image.jimcdn.com/app/cms/image/transf/none/path/s3ecb96fc918b9a61/image/ib217f557c070fa0a/version/1545999570/karikatur-finanzmarktkrise-finanzkrise-2008-bankenkrise.jpg </a>
                <a target='_blank' href='https://c.nau.ch/i/ALXW1/680/tankstelle-ansturm.jpg'>https://c.nau.ch/i/ALXW1/680/tankstelle-ansturm.jpg</a>
                <h5>Schweiz und USA im Vergleich</h5>
                <a target='_blank' href='https://vilan24.ch/articles/157478-arbeitslosigkeit-september-2022'>https://vilan24.ch/articles/157478-arbeitslosigkeit-september-2022</a>
                <h5>Auswirkungen einer Inflation</h5>
                <a target='_blank' href='https://bitpanda-academy.imgix.net/1bc7a2fa-3bad-4ae9-a910-b0a90936bf4e/was-ist-inflation-personal-finance-10-bitpanda-academy.png?auto=compress%2Cformat&fit=min&fm=jpg&q=80&w=2100'>https://bitpanda-academy.imgix.net/1bc7a2fa-3bad-4ae9-a910-b0a90936bf4e/was-ist-inflation-personal-finance-10-bitpanda-academy.png?auto=compress%2Cformat&fit=min&fm=jpg&q=80&w=2100</a>
                <a target='_blank' href='https://www.test.de/file/image/e2/91/97d82085-69bb-4b59-9523-b30add955ffb-web/5887810_inflation-a2206.jpg'>https://www.test.de/file/image/e2/91/97d82085-69bb-4b59-9523-b30add955ffb-web/5887810_inflation-a2206.jpg</a>
                <h5>Finanz- vs. Wirtschaftskrise</h5>
                <a target='_blank' href='https://www.handelsblatt.com/finanzen/banken-versicherungen/banken/bankraeuber-markige-zitate-zur-finanzkrise/3249780.html '>https://www.handelsblatt.com/finanzen/banken-versicherungen/banken/bankraeuber-markige-zitate-zur-finanzkrise/3249780.html</a>
                <h5>Inflation</h5>
                <a target='_blank' href='https://www.vorsorge-schafft-sicherheit.de/hyperinflation-deutschland-als-lebensmittel-millionen-kosteten/ '>https://www.vorsorge-schafft-sicherheit.de/hyperinflation-deutschland-als-lebensmittel-millionen-kosteten/</a>

            </p>
       
        </div>
        </body> 
  )
}

function C6() {
  const [count, setCount] = useState(0)

    console.log("rerender in App")
    const [startpreis,setStartpreis] = useState(0);
    const [startpreis2,setStartpreis2] = useState(0);
    const [endpreis,setEndpreis] = useState(0);
    const [zeitraum,setZeitraum] = useState(0);
    const [inflation, setInflation] = useState(0);
    const [outgabe, setOutgabe] = useState(0)
    function swag(startpreis, zeitraum, inflation){
        let prosent = 1+inflation/100;
        let prosenter = 1 - inflation/100;
        let y = startpreis;
        let f = startpreis;
        for (let i = 0; i < zeitraum; i++) {
            let x = y*prosent
            y=x
            let g = f*prosenter
            f=g
        }
        setEndpreis(y);
        setOutgabe(f);
        setStartpreis2(startpreis);
    }
    const data = [
        {
          name: "Leitzins",
          Startpreis: startpreis2,
          Benötigt_für_den_selben_Wert: endpreis,
          Neuer_Wert: outgabe
        }
      ];
  return (
        <body>
        <div className="header">
            <a className="title">Wirtschaftskrise oder Finanzkrise</a>
            <div className="header-right">
            <Link to="/">
                        <img src={"home.png"} id="nav-home-img" alt="Home" />
            </Link>
            </div>
        </div>

        <div className="vertical">
                <nav className="sidenav">
                    <Link className={"hinter"} to="/c4">Finanz- oder Wirtschaftskrise?</Link>
                    <Link className={"hinter"} to="/achtvs">2008 verglichen mit 2022</Link>
                    <Link className={"hinter"} to="/c2">Schweiz und USA im Vergleich</Link>
                    <Link className={"hinter"} to="/c5">Inflation</Link>
                    <Link className={"hinter"} to="/c3">Auswirkungen einer Inflation</Link>
                    <Link className={"hinter"} to="/c6">Inflationsrechner</Link>
                    <Link className={"hinter"} to="/c7">Quellenverzeichnis</Link>
                </nav>
        </div>
        <div className={"man"}>
            <div id='outgabe'> 
               
               <div>
        <h2>Endpreis berechnen</h2>
          <table>
              <tr>
                  <th>Startpreis</th>
                  <th>Inflation pro Jahr in %</th>
                  <th>Zeitraum</th>
              </tr>
              <tr>
                  <td><input type={"number"} onChange={(event) => {
                      setStartpreis(event.target.value)
                  }}></input></td>
                  <td><input type={"number"} onChange={(event) => {
                      setInflation(event.target.value)
                  }}></input></td>
                  <td><select onChange={(e)=> setZeitraum(e.target.value)}>
                      <option value={0}>Bitte Wählen</option>
                      <option value={1}>1 Jahr</option>
                      <option value={2}>2 Jahre</option>
                      <option value={3}>3 Jahre</option>
                      <option value={4}>4 Jahre</option>
                      <option value={5}>5 Jahre</option>
                      <option value={6}>6 Jahre</option>
                      <option value={7}>7 Jahre</option>
                      <option value={8}>8 Jahre</option>
                      <option value={9}>9 Jahre</option>
                      <option value={10}>10 Jahre</option>
                      <option value={11}>11 Jahr</option>
                      <option value={12}>12 Jahre</option>
                      <option value={13}>13 Jahre</option>
                      <option value={14}>14 Jahre</option>
                      <option value={15}>15 Jahre</option>
                      <option value={16}>16 Jahre</option>
                      <option value={17}>17 Jahre</option>
                      <option value={18}>18 Jahre</option>
                      <option value={19}>19 Jahre</option>
                      <option value={20}>20 Jahre</option>
                  </select></td>
              </tr>
          </table> 
          <br></br>
          <button onClick={() => swag(startpreis, zeitraum, inflation)}>Berechnen</button>
          <p>Gesamtkosten nach {zeitraum} Jahren:</p>
          <p>CHF {endpreis}</p>
          <p>Gesamtwert nach {zeitraum} Jahren:</p>
          <p>CHF {outgabe}</p>
          <div id='diagramm'>
            <ResponsiveContainer width='100%' height={400}>
            <BarChart width={150} height={400} data={data}>
            <Bar dataKey="Startpreis" fill="#8884d8" />
            <Bar dataKey="Benötigt_für_den_selben_Wert" fill="#00008b" />
            <Bar dataKey="Neuer_Wert" fill="#bb70ff" />
            <Legend />
            </BarChart>
            </ResponsiveContainer>
        </div>
          </div>
        </div></div>
        </body> 
  )
}

function Achtvs (){
    return (
        <body>
        <div className="header">
            <a className="title">Wirtschaftskrise oder Finanzkrise</a>
            <div className="header-right">
            <Link to="/">
                        <img src={"home.png"} id="nav-home-img" alt="Home" />
            </Link>
            </div>
        </div>

        <div className="vertical">
                <nav className="sidenav">
                    <Link className={"hinter"} to="/c4">Finanz- oder Wirtschaftskrise?</Link>
                    <Link className={"hinter"} to="/achtvs">2008 verglichen mit 2022</Link>
                    <Link className={"hinter"} to="/c2">Schweiz und USA im Vergleich</Link>
                    <Link className={"hinter"} to="/c5">Inflation</Link>
                    <Link className={"hinter"} to="/c3">Auswirkungen einer Inflation</Link>
                    <Link className={"hinter"} to="/c6">Inflationsrechner</Link>
                    <Link className={"hinter"} to="/c7">Quellenverzeichnis</Link>
                </nav>
        </div>
        <div className={"man"}>
            <p id={"text"}><h3>2008 vs 2022</h3>

                <h3>2008</h3>
                
                Der Ursprung der Finanzkrise 2008 liegt im Bau Wahn der USA in den 90ern. Die Hypothekarzinsen wurden niedrig gehalten, um die Wirtschaft zu fördern. So konnten sich viele neue Menschen eine Eigentum Immobilie leisten. Die Immobilien stiegen also im Preis und gingen mit der hohen Nachfrage mit. Die Banken verwalteten ihre Hypotheken als Collateralized Debt Obligations.
                Mithilfe von denen konnten die Banken dann mit den Hypotheken handeln.<img src='caricature.jpg' id='gazer'></img>
                Diese CDO’s hatten eine sehr hohe Rendite und waren somit auf dem Markt extrem beliebt. Um diese hohe Nachfrage zu decken, vergaben die Banken Kredite an Kreditunwürdige Personen. Sie riskierten also, dass die Personen nach gewisser Zeit ihre Zinsen nicht mehr leisten können. Das stellt jedoch noch kein Problem dar, da die Immobilien wertstabil waren.
                Dieses System hat nicht lange funktioniert. Im Jahr 2005 kamen die Komplikationen. Immer mehr mussten Ihr Haus opfern, um Ihre Hypothek zurückzuzahlen. Dadurch wurde das Angebot der Immobilien auf dem Markt enorm gesteigert und der Logische Schluss daraus war der drastische Fall der Preise. Dasselbe passierte mit den CDO’s.
                Somit verloren Investoren und Banken Milliarden an US Dollar. Daraus folgten gravierende Insolvenzen und massivster Stellenabbau.
                

                <h3>2022</h3>
                
                Gegen Ende von 2021 schien sich die Wirtschaftslage nach dem Tief der Corona Pandemie wieder erholt zu haben. Die Deflation wurde abgewendet. Jedoch wird die Deckung der Nachfrage immer knapper. Begonnen hat es mit Luxusartikeln und mittlerweile sind Lebenswichtige Rohstoffe und Lebensmittel betroffen.<img src='tank.jpg' id='gazero'></img> Also könnte diese Knappheit in einer Wirtschaftskrise enden. Diese Knappheit wurde durch die Pandemie verursacht. Als ob diese Übel nicht gross genug wäre kam noch der Ukraine Krieg dazu und daraus resultiert eine Weltweite Inflation und Gas- und Ölkrise.

                Der Preis dieser Güter ist enorm angestiegen und lässt Unmengen an Nationen in die Krise rutschen.
                In China entstand eine Stromkrise, welche zu einem Massiven Fall der Textilien, Kunststoffen und Maschinenteilen auf dem Markt führte. Durch Mangel an Arbeitskräften im Lieferdienst kommt es zudem auch noch zu enormen Lieferengpässen. Also kann man sagen 2022 deutet alles auf eine Wirtschaftskrise.

                <h3>Vergleich</h3>
                Die beiden Krisen sind schwer zu vergleichen, da es sich 2008 um eine Finanzkrise und 2022 um eine Rohstoffkrise handelt. Bei beiden haben sich die Krisen angebahnt, jedoch kam sie 2022 durch zwei extreme Ereignisse: Die Coronakrise und Der Ukraine Krieg, während die Krise im Jahre 2008 durch eine Falscheinschätzung der Amerikanischen Banken ausgelöst wurde. Im Jahre 2008 kam es zu einem Überschuss von Immobilien auf dem Markt und somit zu einem Fall der Immobilienpreise, 2022 jedoch fehlt das Angebot für Öl und Gas, wodurch die Preise in absurde höhen schossen. Somit unterscheiden sich die Beiden Krisen auch in diesem Punkt extrem.
                Die Krise 2022 folgt auf eine bereits überstandene aber noch nicht komplett behobene Krise. Die Corona Krise hatte grösseren Einfluss als erwartet und zerstörte die Wirtschaft. 2008 war alles auf Grundlegenden Fehlern aufbauend und hatte kein gravierendes Ereignis vor dem Ausbruch. Beide eskalierten in ähnlichem Ausmass und warfen die Wirtschaft wieder grosse Schritte zurück. Das Ausmass ist bei beiden Krisen international und betrifft die gesamte Welt. Wie die Wirtschaft sich von der jetzigen Lage erholen wird ist noch unklar, daher kann man dies nicht vergleichen.
                Zum Schluss würde ich behaupten, dass 2022 auch zu einer historischen Wirtschaftskrise entwicklen wird.

            </p>

        </div>
        </body> 
  )
}

export default App
